import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import * as uuid from "uuid";
import { AngularFirestore } from "@angular/fire/firestore";
import { gods, God } from "./models/GodModel";
import { environment } from "src/environments/environment";
import * as CryptoJS from "crypto-js";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/internal/operators/catchError";
import { of } from "rxjs/internal/observable/of";
import { Observable } from "rxjs/internal/Observable";
import { VGS, vgsLines } from "./models/VGS";
import { abilitySoundFiles } from "./models/AbilitySoundFiles";
import { data, allData } from "../app/models/data";

@Injectable({
  providedIn: "root",
})
export class HomeGuard implements CanActivate {
  ratata = "ok";
  cryptKey = environment.cryptKey;
  god;
  quote;
  abilitySounds = [];
  abilityText = [];
  vgs = [];

  abilityTextHistory = [];

  counter = 1;
  allData: data[] = JSON.parse(JSON.stringify(allData));
  GodsGamemode: God[] = JSON.parse(JSON.stringify(gods));
  QuoteGamemode: God[] = JSON.parse(JSON.stringify(gods));
  GodsAbilityText: God[] = JSON.parse(JSON.stringify(gods));
  vgsGamemode: VGS[] = JSON.parse(JSON.stringify(vgsLines));
  abilitySoundGamode: string[] = JSON.parse(JSON.stringify(abilitySoundFiles));

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private store: AngularFirestore,
    private httpClient: HttpClient
  ) {}
  canActivate() {
    let user_id = localStorage.getItem("smitesavvy_id");
    if (user_id === null || user_id === undefined) {
      localStorage.setItem("smitesavvy_id", uuid.v4());
    }

    let currentDate = localStorage.getItem("currentD");
    let today = this.daysIntoYear(new Date()).toString();

    if (
      this.daysIntoYear(new Date()) >= 1 &&
      this.daysIntoYear(new Date()) <= 5
    )
      today = (366 + this.daysIntoYear(new Date())).toString();
    else if (
      this.daysIntoYear(new Date()) > 5 &&
      this.daysIntoYear(new Date()) < 366 &&
      this.daysIntoYear(new Date()) !== 365
    )
      today = (this.daysIntoYear(new Date()) - 5).toString();
    // this.getToday(today);
    if (currentDate === null || currentDate === undefined) {
      localStorage.setItem("currentD", today);
      this.clearLocalstorage();
      this.getToday(today);
    } else if (currentDate !== today) {
      localStorage.setItem("currentD", today);
      this.clearLocalstorage();
      this.getToday(today);
    }
    // this.fillDatabase();
    return true;
  }

  clearLocalstorage() {
    localStorage.removeItem("completedGodAbilitiesSound");
    localStorage.removeItem("completedGodAbilitiesText");
    localStorage.removeItem("guessGodNames");
    localStorage.removeItem("guessQuoteNames");
    localStorage.removeItem("completedVGSCommands");
    localStorage.removeItem("completedSPL");
    localStorage.removeItem("guessFeudNames");

    localStorage.removeItem("godsState");
    localStorage.removeItem("quoteState");
    localStorage.removeItem("abilitySoundsState");
    localStorage.removeItem("abilityTextState");
    localStorage.removeItem("vgsState");
    localStorage.removeItem("splState");
    localStorage.removeItem("feudState");

    for (let i = 1; i <= 5; i++) {
      localStorage.removeItem("selectedAbilitiesSound_" + i);
      localStorage.removeItem("selectedAbilities_" + i);
      localStorage.removeItem("selectedAbilitiesText_" + i);
      localStorage.removeItem("selectedVGSCommands_" + i);
      localStorage.removeItem("splQuestion_" + i);
    }
  }

  getToday(todayString: string) {
    let todayData: data = this.allData.filter((d) => {
      return d.day === todayString;
    })[0];

    localStorage.setItem("gods_today", todayData.gods);
    localStorage.setItem("quote_today", todayData.quote);
    localStorage.setItem("abilitySounds_today", todayData.abilitySounds);
    localStorage.setItem("abilityText_today", todayData.abilityText);
    localStorage.setItem("vgs_today", todayData.vgs);
    localStorage.setItem("spl_today", todayData.spl);
    localStorage.removeItem("feud_today");
    if (
      new Date().getUTCDay() === 0 ||
      new Date().getUTCDay() === 6
      // new Date().getUTCDay() === 5 ||
      // new Date().getUTCDay() === 4
    ) {
      localStorage.setItem("feud_today", todayData.feud);
    } else {
      localStorage.removeItem("feud_today");
    }
    // console.log(todayData);

    // this.store
    //   .collection("gamemodes")
    //   .doc(todayString)
    //   .ref.get()
    //   .then((doc) => {
    //     let data = doc.data();
    //     localStorage.setItem("gods_today", data.gods);
    //     localStorage.setItem("quote_today", data.quote);
    //     localStorage.setItem("abilitySounds_today", data.abilitySounds);
    //     localStorage.setItem("abilityText_today", data.abilityText);
    //     localStorage.setItem("vgs_today", data.vgs);
    //     localStorage.setItem("spl_today", data.spl);
    //     if (
    //       new Date().getUTCDay() === 0 ||
    //       new Date().getUTCDay() === 6 ||
    //       new Date().getUTCDay() === 5 ||
    //       new Date().getUTCDay() === 4
    //     ) {
    //       localStorage.setItem("feud_today", data.feud);
    //     } else {
    //       localStorage.removeItem("feud_today");
    //     }
    //   });
  }

  daysIntoYear(date) {
    return Math.floor(
      (Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      ) -
        Date.UTC(date.getUTCFullYear(), 0, 0, 0, 0, 0)) /
        24 /
        60 /
        60 /
        1000
    );
  }

  fillDatabase() {
    const randomGod = Math.floor(Math.random() * this.GodsGamemode.length);
    let godGamemode = this.GodsGamemode[randomGod];
    this.GodsGamemode.splice(randomGod, 1);
    this.god = godGamemode.name;
    if (this.GodsGamemode.length === 0) {
      this.GodsGamemode = JSON.parse(JSON.stringify(gods));
    }

    const randomQuote = Math.floor(Math.random() * this.QuoteGamemode.length);

    let godQuote = this.QuoteGamemode[randomQuote];
    let randomTaunt = Math.floor(Math.random() * godQuote.taunts.length);

    godQuote.taunts.splice(randomTaunt, 1);
    if (godQuote.taunts.length === 0) {
      this.QuoteGamemode.splice(randomQuote, 1);
    }
    let fullQuoteData = {
      god: godQuote.name,
      taunt: randomTaunt,
    };
    this.quote = fullQuoteData;
    if (this.QuoteGamemode.length === 0)
      this.QuoteGamemode = JSON.parse(JSON.stringify(gods));

    // Ability Sounds

    this.getAbilitySounds();
  }

  fileExists(url: string): Observable<boolean> {
    return this.httpClient
      .get(url, { observe: "response", responseType: "blob" })
      .pipe(
        map(() => {
          return true;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  getAbilitySounds() {
    for (let i = 1; i <= 5; i++) {
      const randomSoundNumber = Math.floor(
        Math.random() * this.abilitySoundGamode.length
      );
      let randomSound = this.abilitySoundGamode[randomSoundNumber];
      this.abilitySoundGamode.splice(randomSoundNumber, 1);
      this.abilitySounds.push(randomSound);
      if (this.abilitySoundGamode.length === 0) {
        this.abilitySoundGamode = JSON.parse(JSON.stringify(abilitySoundFiles));
      }
    }
    this.complete();
  }

  complete() {
    for (let i = 1; i <= 5; i++) {
      let currentAbilityText = "";
      while (true) {
        const random = Math.floor(Math.random() * this.GodsAbilityText.length);

        let randomGodName = this.GodsAbilityText[random].name;

        randomGodName = randomGodName.replace(/ +/g, "");
        randomGodName.replace("'", "");

        let numbers = ["1", "2", "3", "4"];

        let randomNumber;
        let toComplete = false;
        while (true) {
          if (numbers.length === 0) {
            this.GodsAbilityText.splice(random, 1);
            break;
          }
          randomNumber = Math.floor(Math.random() * numbers.length);

          if (
            this.abilityTextHistory.includes(
              randomGodName + "_" + numbers[randomNumber]
            )
          ) {
            numbers.splice(randomNumber, 1);
          } else {
            toComplete = true;
            break;
          }
        }

        if (toComplete) {
          currentAbilityText = randomGodName + "_" + numbers[randomNumber];

          this.abilityText.push(currentAbilityText);
          this.abilityTextHistory.push(currentAbilityText);
          break;
        }

        if (this.GodsAbilityText.length === 0) {
          this.GodsAbilityText = JSON.parse(JSON.stringify(gods));
          this.abilityTextHistory = [];
        }
      }
    }

    let vgsCommands = this.vgsGamemode.map((vgs) => vgs.command);

    for (let i = 1; i <= 5; i++) {
      // while (true) {
      const randomVGS = Math.floor(Math.random() * vgsCommands.length);
      let vgsCmd = vgsCommands[randomVGS];
      this.vgsGamemode.splice(randomVGS, 1);
      this.vgs.push(vgsCmd);
      if (this.vgsGamemode.length === 0) {
        this.vgsGamemode = JSON.parse(JSON.stringify(vgsLines));
      }
    }

    let godCrypt = CryptoJS.AES.encrypt(
      JSON.stringify(this.god),
      this.cryptKey
    ).toString();
    let quoteCrypt = CryptoJS.AES.encrypt(
      JSON.stringify(this.quote),
      this.cryptKey
    ).toString();
    let abilitySoundsCrypt = CryptoJS.AES.encrypt(
      JSON.stringify(this.abilitySounds),
      this.cryptKey
    ).toString();
    let abilityTextCrypt = CryptoJS.AES.encrypt(
      JSON.stringify(this.abilityText),
      this.cryptKey
    ).toString();
    let vgsCrypt = CryptoJS.AES.encrypt(
      JSON.stringify(this.vgs),
      this.cryptKey
    ).toString();

    let today = this.daysIntoYear(new Date()) + this.counter;
    this.store.collection("gamemodes").doc(today.toString()).set({
      gods: godCrypt,
      quote: quoteCrypt,
      abilitySounds: abilitySoundsCrypt,
      abilityText: abilityTextCrypt,
      vgs: vgsCrypt,
    });

    this.counter++;
    if (this.counter <= 297) {
      this.abilitySounds = [];
      this.abilityText = [];
      this.vgs = [];
      this.fillDatabase();
    }
  }
}
